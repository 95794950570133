<template>
  <div class="member">
    <el-row class="member-filter">
      <el-form label-width="auto" size="mini">
        <el-col :span="14">
          <el-form-item label="用户搜索:">
            <div class="member-filter-search">
              <el-select v-model="page.contentType">
                <el-option v-for="item in contentTypes"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>

              <el-input placeholder="请输入用户昵称" class="member-input-filter" clearable
                        v-model="page.content"
                        prefix-icon="el-icon-search"></el-input>

              <el-button type="primary" size="small" @click="loadOrder">筛选</el-button>
              <el-button type="text" size="small" @click="showFilter=!showFilter">{{showFilter? '收起': '更多'}}筛选
              </el-button>
            </div>

          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="注册时间:">
            <time-range @change="handleMakeTime"/>
          </el-form-item>
        </el-col>

        <template v-if="showFilter">
          <el-col :span="8">
            <el-form-item label="来源渠道:">
              <el-select placeholder="请选择来源渠道" v-model="page.fromChanel" class="member-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in fromTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户等级:">
              <el-select placeholder="请选择用户等级" class="member-input-filter" v-model="page.vip"
                         @change="loadOrder">
                <el-option v-for="item in memberTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>


      </el-form>
    </el-row>

    <div>
      <el-table :data="members">
        <empty slot="empty"></empty>
        <el-table-column label="用户" min-width="128">
          <template slot-scope="scope">
            <div class="flex">
              <div >
                <el-image :src="scope.row.avatar"></el-image>
              </div>
              <div style="margin-left: 8px;">
                <div class="flex-text">
                  {{ scope.row.niceName }}
                </div>
                <div class="flex-mobile">
                  {{ scope.row.phone }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员等级" prop="vipName">
        </el-table-column>
        <el-table-column label="消费金额" prop="fee" sortable="custom" align="center">
          <template slot-scope="scope">
            <div class="yk-text">￥{{ (scope.row.fee / 100) | F1000 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="消费次数" prop="feeTimes" sortable="custom" align="center">
        </el-table-column>
        <el-table-column label="最近消费时间" prop="last_buy_time" sortable="custom" min-width="100">
          <template slot-scope="scope">
            <div class="yk-text" v-if="scope.row.lastFeeAt > 0">
              {{new Date(scope.row.lastFeeAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
            </div>
            <span v-else>暂无消费</span>
          </template>
        </el-table-column>
        <el-table-column label="最后访问时间" prop="lastAccessAt" sortable="custom" min-width="100" align="center">
          <template slot-scope="scope">
            <div class="yk-text">
              {{new Date(scope.row.lastAccessAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" prop="createdAt" sortable="custom" min-width="100" align="center">
          <template slot-scope="scope">
            <div class="yk-text">
              {{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="来源渠道" prop="fromChanel">
          <template slot-scope="scope">
            <div class="yk-text">
              {{ scope.row.fromChanel | filterFormChanel }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80" fixed="right">
          <template slot-scope="scope">


            <el-button type="text" @click="handleDetail(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="page.curPage"
        :page-size="page.limit"
        layout="total, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
  export default {
    name: '',
    props: {
      pagination: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    mounted: function () {
      let opt = []
      opt.push({label: `所有VIP`, value: ``})
      for (let i = 1; i <= 10; i++) {
        opt.push({label: `VIP${i}`, value: `VIP${i}`})
      }
      this.memberTypeOption = opt
      this.loadOrder()
    },
    data: function () {
      return {
        members: [],
        showFilter: false,
        uuid: '',
        fromTypeOption: [
          {label: '全部渠道', value: ''},
          {label: '微信小程序', value: 'wx'},
          {label: '支付宝小程序', value: 'ali'},
        ],
        memberTypeOption: [
        ],
        contentTypes: [
          {label: '用户昵称', value: 1},
          {label: '手机号', value: 2},
        ],
        makeTimeRange: [],
        loading: false,
        page: {
          current: 1,
          content: '',
          contentType: 1,
          startAt: 0,
          endAt: 0,
          fromChanel: '',
          vip: '',
          start: 0,
          limit: 10,
        }
      }
    },
    filters: {

      filterFormChanel(ch) {
        switch (ch) {
          case 'h5':
            return '移动网页'
          case 'wx':
            return '微信小程序'
          case 'aliMini':
            return '支付宝小程序'
        }
      },

      filterMakeStyle(style) {
        switch (style) {
          case 1:
            return '测试'
          case 2:
            return '无支付'
          case 3:
            return '支付'
        }
      }
    },
    methods: {
      handleMakeTime(ts) {
        this.page.startAt = ts[0]
        this.page.endAt = ts[1]
        this.loadOrder()
      },

      handleCurrentChange(value) {
        this.page.curPage = value
        this.page.start = (value - 1) * this.page.limit
        this.$api('mkt.user.list', {...this.pagination, ...this.page}).then(res => {
          this.members = res || []
        })
      },
      handleDetail(detail) {
        this.$router.push({name: 'mkt.member.user.detail', params: {uuid: detail.uuid}})
      },
      loadOrder() {
        let vue = this
        this.$api('mkt.user.union', {...this.pagination, ...this.page}).then(res => {
          vue.members = res.list || []
          vue.page.total = parseInt(res.total)
        })
      },

    },
  }
</script>

<style lang="scss" scoped>
  .member {
    &-filter {
      margin: $large-space $middle-space;

      &-search {
        display: flex;
        align-items: center;

        .el-input {
          margin-left: $small-space;
          margin-right: $middle-space;
        }
      }
    }
    .yk-text{
      font-size: 13px;
    }

    .el-table {
      thead {
        tr {
          th {
            bmember-bottom: 1px solid;

          }
        }
      }
    }

    .flex {
      display: flex;
      justify-items: center;
      align-items: center;
      .el-image{
        max-width: 45px;
      }

      &-text {
        font-size: 13px;
        color: $color-black;
        font-weight: 500;
      }

      &-mobile {
        font-size: 12px;
        color: #1f1f1f;
      }
    }

  }
</style>
