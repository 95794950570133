<template>
  <div class="mkt-user">
    <div style="margin-bottom: 24px">
      <p style="margin-bottom: 24px">
        <span class="page-title">用户数据</span>
        <span class="yk-tip">数据统计时间：{{ time }}</span>
      </p>

      <div class="statistic">
        <div class="statistic-item clear-fix">
          <div class="statistic-item-block">
            <p class="block-title">总用户数</p>
            <p class="block-number">{{statistics.user_number.all / 100 | I1000}}</p>
            <p class="block-rate">昨日: <span>{{statistics.user_number_yesteday.all | I1000}} </span></p>
          </div>
          <div class="statistic-item-block">
            <p class="block-title">昨日新增用户数</p>
            <p class="block-number">{{statistics.user_number.all / 100 | I1000}}</p>
            <p class="block-rate">前日: <span>{{statistics.user_number_yesteday.all | I1000}} </span></p>
          </div>
          <div class="statistic-item-block">
            <p class="block-title">昨日支付人数</p>
            <p class="block-number">{{statistics.user_number.all / 100 | I1000}}</p>
            <p class="block-rate">前日: <span>{{statistics.user_number_yesteday.all | I1000}} </span></p>
          </div>
          <div class="statistic-item-block">
            <p class="block-title">昨日客单价</p>
            <p class="block-number">{{statistics.user_number.all / 100 | I1000}}</p>
            <p class="block-rate">前日: <span>{{statistics.user_number_yesteday.all | I1000}} </span></p>
          </div>
        </div>
      </div>
    </div>

    <member></member>

  </div>
</template>
<script type="text/javascript">

  import Member from "@/components/Member";

  export default {
    components: {
      Member
    },
    data() {
      return {
        load: {
          show: false,
          type: 1
        },
        list: [],
        pagination: {
          current: 1,
          content: '',
          contentType: 0,
          startAt: 0,
          endAt: 0,
          fromChanel: '',
          vip: '',
          start: 0,
          limit: 10,
        },
        statistics: {
          user_number: {
            all: 0
          },
          user_number_yesteday: {
            all: 0
          },
          user_grow_today: {
            all: 0
          },
          user_grow_yesteday: {
            all: 0
          },
          pay_amount_today: {
            all: 0
          },
          pay_amount_yesteday: {
            all: 0
          },
          average_amount_today: {
            all: 0
          },
          average_amount_yesteday: {
            all: 0
          }
        },

        time: ''
      };
    },
    async mounted() {
      this.getTime();
    },
    methods: {
      sortChange(e) {
        this.keyword.sort = {}
        this.keyword.sort[e.prop] = e.order == 'ascending' ? 'ASC' : 'DESC';
        this.search(this.keyword);
      },

      getTime: function () {
        let now = new Date();
        let yy = now.getFullYear();
        let mm = now.getMonth() + 1;
        let dd = now.getDate();
        let hh = now.getHours();
        let ii = now.getMinutes();
        let ss = now.getSeconds();
        let clock = yy + "-";
        if (mm < 10) clock += "0";
        clock += mm + "-";
        if (dd < 10) clock += "0";
        clock += dd + " ";
        if (hh < 10) clock += "0";
        clock += hh + ":";
        if (ii < 10) clock += '0';
        clock += ii + ":";
        if (ss < 10) clock += '0';
        clock += ss;
        this.time = clock;
      },
    },
    filters: {
    }
  };
</script>
<style lang="scss" scoped>
  .mkt-user {
    @include container();
    .yk-tip{
      margin-left: $small-space;
    }
    .statistic {
      display: flex;
      width: 100%;
      margin-top: $small-space;
      border-bottom: 1px solid $content-border-color;
      margin-bottom: $middle-space;


      &-item {
        width: 100%;

        &-block {
          width: 24%;
          float: left;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 32px;

          .block-title {
            line-height: 22px;

            span {
              font-size: 12px;
            }
          }

          .block-number {
            line-height: 36px;
            font-size: 24px;
          }

          .block-rate {
            line-height: 21px;
            font-size: 12px;
          }
        }
      }

    }
  }

</style>
